<template>
     <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol>
                <CInput
                    label="İndirim Yüzdesi"
                    horizontal
                    type="number"
                    v-model="data.discountPercent"
                />
            </CCol>
            <CCol>
                <CInput
                    label="İndirim Miktarı"
                    horizontal
                    type="number"
                    v-model="data.discountAmount"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Min. Satış Fiyatı"
                    horizontal
                    type="number"
                    v-model="data.minSalePriceTotal"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Max Adet"
                    horizontal
                    type="number"
                    v-model="data.maxCount"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Exec Order"
                    horizontal
                    type="text"
                    v-model="data.execOrder"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Min. İlişik Ürün Adedi"
                    horizontal
                    type="text"
                    v-model="data.relatedProductMinCount"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Grup"
                    horizontal
                    type="text"
                    v-model="data.groupName"
                />
            </CCol>
            <CCol>
                <CSelect
                    label="Kural Tipi"
                    horizontal
                    placeholder="Seçiniz"
                    :options="ruleTypes"
                    :value.sync="data.promocodeRuleTypeId"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CSelect
                    label="Ürün Türü"
                    horizontal
                    placeholder="Seçiniz"
                    :options="productTypes"
                    :value.sync="data.productTypeId"
                />
            </CCol>
            <CCol>
                <CSelect
                    label="İlişik Ürün Türü"
                    horizontal
                    placeholder="Seçiniz"
                    :options="productTypes"
                    :value.sync="data.relatedProductTypeId"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol col="2">
                Satış Fiyatı Üzerinden
            </CCol>
            <CCol col="2">
                <input type="checkbox" name="select"
                    value="value" v-model="data.discountOverSalePriceFlag"
                    style="height:16px; width:16px; vertical-align: middle;">

            </CCol>
            <CCol col="2">
                Clearence Ürün Dahil
            </CCol>
            <CCol col="2">
                <input type="checkbox" name="select"
                    value="value" v-model="data.clearanceIncludedFlag"
                    style="height:16px; width:16px; vertical-align: middle;">

            </CCol>
            <CCol col="2">
                Grup Adı Ekli
            </CCol>
            <CCol col="2">
                <input type="checkbox" name="select"
                    value="value" v-model="data.groupNameIncludedFlag"
                    style="height:16px; width:16px; vertical-align: middle;">

            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "UserForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        computed:{
            productTypes: function(){
                let data = []
                this.$store.getters.activeProductTypes.map(p => data.push({value:p.id, label:p.name}))
                return data;
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                ruleTypes: [
                    {value: "1", label: "CartItemRule"},
                    {value: "2", label: "CartRule"},
                    {value: "3", label: "ShippingRule"}
                ],
            }
        },
    }
</script>